








































































.price-item
  display flex
  justify-content space-between
  align-items center
